<template>
  <v-dialog v-model="dialog" width="600" :persistent="loading">
    <!-- LOADING -->
    <v-card v-show="loading">
      <v-card-title>Allekirjoitusprosessi käynnissä. Odota hetki...</v-card-title>

      <v-card-text class="text-center">
        <v-progress-circular
          class="text-center mb-2"
          :value="100"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-text>
    </v-card>

    <!-- NOT LOADING -->
    <v-card v-show="!loading">
      <v-card-title
        >Signhero | Sähköinen allekirjoitus sop.num.
        {{ signatureItem.contractNumber }}</v-card-title
      >

      <!-- Not send -->
      <v-card-text v-if="!hasProcessId" class="mt-2">
        <!-- Show signers before sending -->
        <show-signers-before-send
          ref="showSignerBeforeSend"
          :noEmailSigners="noEmailSigners"
          :allSigners="allSigners"
          :contractType="contractType"
        ></show-signers-before-send>

        <!-- Status -->
        <h3 class="mb-1">Status</h3>

        <p style="margin-bottom: 25px" :class="signatureColorClass">
          {{ getSignatureState(signatureItem) }}
        </p>

        <v-btn class="mt-2" color="info" @click="sendSignature()"
          >Lähetä allekirjoitettavaksi</v-btn
        >
      </v-card-text>

      <!-- If sent -->
      <v-card-text v-if="hasProcessId" class="mt-2">
        <h3 class="mb-1">Status</h3>

        <p v-if="!isReady">Ladataan allekirjoittajia...</p>

        <div v-else>
          <p class="mb-1" :class="signatureColorClass">
            {{ getSignatureState(signatureItem) }}
          </p>

          <div>
            <div v-for="signer in signers" :key="signer.uuid">
              <span
                ><strong>{{ signer.name }} ({{ signer.email }})</strong>
              </span>
              <span> | {{ formatStatus(signer.status) }}</span>
            </div>
          </div>

          <div v-if="signers.length > 0 && profile" class="mt-2">
            <v-btn
              v-if="checkStatuses(signers)"
              class="mr-1 mb-2"
              color="info"
              @click="
                $emit('createsignature');
                dialog = false;
              "
              >Allekirjoita dokumentti</v-btn
            >

            <!-- cancel -->
            <v-btn
              class="mb-2"
              v-if="signatureItem.signature.status == 'pending'"
              color="warning"
              outlined
              @click="cancelSigningProcess(signatureItem.signature.processId)"
            >
              Peruuta allekirjoitukset
            </v-btn>

            <!-- delete -->
            <v-btn
              class="mb-2"
              v-if="signatureItem.signature.status == 'completed'"
              color="error"
              outlined
              @click="deleteSigningProcess(signatureItem.signature.processId)"
            >
              Poista allekirjoitusprosessi
            </v-btn>
          </div>
        </div>
      </v-card-text>

      <!-- Download contract -->
      <v-card-text v-if="signatureItem.signature.document">
        <v-btn color="primary" @click="download()">Lataa dokumentti</v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import { apiAgent } from "../../services/apiAgent";
import { mapMutations, mapState } from "vuex";
import validations from "@/validations";
import signatureHelpers from "@/utils/signatureHelpers.js";
import ShowSignersBeforeSend from "./ShowSignersBeforeSend.vue";

export default {
  mixins: [mixins],

  components: {
    ShowSignersBeforeSend,
  },

  props: {
    value: { type: Boolean, default: false },
    document: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      signers: [],
      noEmailSigners: [],
      isReady: false,
      profile: {},
      signatureHelpers,
      validations,
    };
  },

  computed: {
    ...mapState("signature", ["loading", "signatureItem", "contractType", "additionalSigners"]),
    ...mapState("account", ["currentUser"]),

    allSigners: {
      get() {
        return this.$store.state.signature.allSigners;
      },
      set(val) {
        this.setSignatureField({ field: "allSigners", val: val });
      },
    },

    hasProcessId() {
      return this.signatureItem.signature.processId;
    },

    userIsAccountOwner() {
      return this.currentUser.ownerOfAccounts.includes(this.currentUser.currentAccount._id);
    },

    signatureColorClass() {
      return `${this.getSignatureColor(this.signatureItem)}--text`;
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value(val) {
      if (val) this.setSignatureField({ field: "additionalSigners", val: [] });
    },
  },

  methods: {
    ...mapMutations("contract", ["replaceContract"]),
    ...mapMutations("othercontract", ["replaceOtherContract"]),
    ...mapMutations("signature", ["setSignatureItem", "setSignatureField", "removeFromAllSigners"]),
    ...mapMutations("pdfViewer", ["openViewer"]),

    startProcess() {
      this.selectSigners();
      this.getProcess();
    },

    sendSignature() {
      if (this.$refs.showSignerBeforeSend.validateAdditionalSigners()) {
        this.$emit("sendsignatureinvitation", {
          allSigners: this.allSigners,
          additionalSigners: this.additionalSigners,
        });
      } else {
        return this.showPopup("Tarkista allekirjoittajat.", "error");
      }
    },

    checkStatuses(signers) {
      let boolean = false;
      signers.forEach((signer) => {
        if (signer.email == this.currentUser.currentAccount.email && this.userIsAccountOwner) {
          boolean = signer.status == "signed" ? false : true;
        }
      });
      return boolean;
    },

    formatStatus(status) {
      if (status == "pending") return "Odottaa allekirjoitusta";
      if (status == "signed") return "Allekirjoitettu";
    },

    selectSigners() {
      if (this.contractType == "rentalContract") {
        this.getRentalContractSigners();
      } else if (this.contractType == "otherContract") {
        this.getOtherContractSigners();
      }
    },

    getRentalContractSigners() {
      const { allSigners, noEmailSigners } = signatureHelpers.getRentalContractSigners(
        this.signatureItem,
        this.currentUser,
        this.userIsAccountOwner
      );

      this.setSignatureField({
        field: "allSigners",
        val: allSigners,
      });
      this.noEmailSigners = noEmailSigners;
    },

    getOtherContractSigners() {
      const { allSigners, noEmailSigners } = signatureHelpers.getOtherContractSigners(
        this.signatureItem
      );

      this.setSignatureField({
        field: "allSigners",
        val: allSigners,
      });
      this.noEmailSigners = noEmailSigners;
    },

    async getProcess() {
      this.isReady = false;

      if (this.signatureItem.signature.processId) {
        try {
          let res2 = await apiAgent.post(`/api/v1/signature/signhero/getProfile`);
          let res = await apiAgent.post(`/api/v1/signature/signhero/retrieveProcess`, {
            processId: this.signatureItem.signature.processId,
            contractType: this.contractType,
          });

          if (res.data.contract) {
            if (this.contractType == "rentalContract") {
              this.replaceContract({ ...res.data.contract });
            } else if (this.contractType == "otherContract") {
              this.replaceOtherContract({ ...res.data.contract });
            }
            // set item to state
            this.setSignatureItem(res.data.contract);
          }

          this.signers = res.data.signers;
          this.profile = res2.data.profile;

          this.isReady = true;
        } catch (err) {
          let error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },

    async download() {
      try {
        const res = await apiAgent({
          method: "GET",
          url:
            `/api/v1/signature/signhero/downloadDocument/` + this.signatureItem.signature.document,
          responseType: "arraybuffer",
        });

        let name;
        if (this.contractType == "rentalContract") {
          name = `Vuokrasopimus_${this.signatureItem.apartment.address} ${this.signatureItem.apartment.apartmentNumber}.pdf`;
        } else {
          name = `Sopimus_${this.signatureItem.contractNumber}_${this.signatureItem.name}.pdf`;
        }

        const blob = new Blob([res.data], { type: "application/pdf" });

        this.openViewer({
          data: blob,
          name,
        });
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },

    async cancelSigningProcess(processId) {
      if (confirm("Haluatko varmasti peruuttaa allekirjoituksen?")) {
        try {
          const res = await apiAgent({
            method: "post",
            url: "/api/v1/signature/signhero/cancel-signing-process",
            data: {
              processId,
              contractType: this.contractType,
            },
          });

          if (res.data.contract) {
            if (this.contractType == "rentalContract") {
              this.replaceContract({ ...res.data.contract });
            } else {
              this.replaceOtherContract({ ...res.data.contract });
            }
            // set to state
            this.setSignatureItem(res.data.contract);
          }
          this.dialog = false;
          this.showPopup("Allekirjoitusprosessi peruttu", "success");
        } catch (err) {
          let error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },

    async deleteSigningProcess(processId) {
      if (confirm("Haluatko varmasti poistaa nykyisen allekirjoituksen?")) {
        try {
          const res = await apiAgent({
            method: "post",
            url: "/api/v1/signature/signhero/delete-signing-process",
            data: {
              processId,
              contractType: this.contractType,
            },
          });

          if (res.data.contract) {
            if (this.contractType == "rentalContract") {
              this.replaceContract({ ...res.data.contract });
            } else {
              this.replaceOtherContract({ ...res.data.contract });
            }
            // set to state
            this.setSignatureItem(res.data.contract);
          }

          this.dialog = false;
          this.showPopup("Allekirjoitusprosessi poistettu", "success");
        } catch (err) {
          let error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },
  },
};
</script>

<style scoped>
.card-oldlist {
  height: 500px;
  overflow-y: scroll;
}
</style>
