<template>
  <v-dialog v-model="dialog" :persistent="loading" max-width="400">
    <v-card class="pa-3">
      <v-card-text class="text-center">
        <h3 class="mb-1">{{ text }}</h3>
        <p class="mb-3" v-if="additionalText">{{ additionalText }}</p>
        <v-progress-circular
          class="text-center mb-1"
          color="primary"
          :value="100"
          indeterminate
        ></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, default: false },
    text: { type: String, default: "" },
    additionalText: { type: String, default: "" },
    loading: { type: Boolean, default: false },
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    loading(val) {
      if (!val) this.dialog = false;
    },
  },
};
</script>

<style></style>
