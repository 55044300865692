var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600","persistent":_vm.loading},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_c('v-card-title',[_vm._v("Allekirjoitusprosessi käynnissä. Odota hetki...")]),_c('v-card-text',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"text-center mb-2",attrs:{"value":100,"indeterminate":"","color":"primary"}})],1)],1),_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('v-card-title',[_vm._v("Visma Sign | Sähköinen allekirjoitus sop.num. "+_vm._s(_vm.signatureItem.contractNumber))]),(!_vm.hasDocumentUuId)?_c('v-card-text',{staticClass:"mt-2"},[_c('show-signers-before-send',{ref:"showSignerBeforeSend",attrs:{"noEmailSigners":_vm.noEmailSigners,"allSigners":_vm.allSigners,"contractType":_vm.contractType}}),_c('v-form',{ref:"form"},[_c('v-menu',{ref:"validUntil",attrs:{"close-on-content-click":false,"min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"prevent-manual-input",rawName:"v-prevent-manual-input"}],attrs:{"value":_vm.formatDate(_vm.validUntilDate),"outlined":"","dense":"","label":"Allekirjoituskutsu voimassa","append-icon":"mdi-calendar","hide-details":"","rules":_vm.validations.required}},'v-text-field',attrs,false),on))]}}],null,false,4073961749),model:{value:(_vm.validMenu),callback:function ($$v) {_vm.validMenu=$$v},expression:"validMenu"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.allowedDates,"first-day-of-week":"1"},on:{"input":function($event){_vm.validMenu = false}},model:{value:(_vm.validUntilDate),callback:function ($$v) {_vm.validUntilDate=$$v},expression:"validUntilDate"}})],1)],1),_c('h3',{staticClass:"mb-1 mt-2"},[_vm._v("Lähettäjä")]),_c('p',[_vm._v(" Allekirjoituskutsun lähettäjän nimi on "),_c('strong',[_vm._v(_vm._s(_vm.currentUser.currentAccount.name))])]),_c('h3',{staticClass:"mb-1 mt-2"},[_vm._v("Status")]),_c('p',{class:_vm.signatureColorClass,staticStyle:{"margin-bottom":"25px"}},[_vm._v(" "+_vm._s(_vm.getSignatureState(_vm.signatureItem))+" ")]),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.sendSignature()}}},[_vm._v("Lähetä allekirjoitettavaksi")])],1):_vm._e(),(_vm.hasDocumentUuId)?_c('v-card-text',{staticClass:"mt-2"},[_c('h3',{staticClass:"mb-1"},[_vm._v("Status")]),(!_vm.isReady)?_c('p',[_vm._v("Ladataan allekirjoittajia...")]):_c('div',[_c('p',{staticClass:"mb-1",class:_vm.signatureColorClass},[_vm._v(" "+_vm._s(_vm.getSignatureState(_vm.signatureItem))+" ")]),_c('div',_vm._l((_vm.invitations),function(signer){return _c('div',{key:signer.uuid},[_c('span',[_c('strong',[_vm._v(_vm._s(signer.name)+" ("+_vm._s(signer.email)+")")])]),_c('span',[_vm._v(" | "+_vm._s(_vm.formatStatus(signer.status)))])])}),0),_c('div',{staticClass:"mt-2"},[(
              _vm.signatureItem.vismaSign.status == 'pending' &&
              _vm.signatureItem.vismaSign.status != 'cancelled'
            )?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"warning","outlined":""},on:{"click":function($event){return _vm.cancelSigningProcess(_vm.signatureItem.vismaSign.documentUuId)}}},[_vm._v(" Peruuta allekirjoitukset ")]):_vm._e(),(
              _vm.signatureItem.vismaSign.status == 'signed' ||
              _vm.signatureItem.vismaSign.status == 'cancelled' ||
              _vm.invitations.length == 0
            )?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"error","outlined":""},on:{"click":function($event){return _vm.deleteSigningProcess(_vm.signatureItem.vismaSign.documentUuId)}}},[_vm._v(" Poista allekirjoitusprosessi ")]):_vm._e()],1)])]):_vm._e(),(_vm.signatureItem.vismaSign.status && _vm.signatureItem.vismaSign.status != 'cancelled')?_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary mb-1"},on:{"click":function($event){return _vm.download()}}},[_vm._v("Lataa dokumentti")])],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Poistu ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }