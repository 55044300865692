<template>
  <!-- Additional signers -->
  <v-form class="mt-1" ref="rentalContractForm">
    <div>
      <v-row dense>
        <v-col v-for="(signer, idx) in additionalSigners" :key="'b' + idx" cols="12" sm="6">
          <p
            style="
              cursor: pointer;
              font-size: 11px;
              margin-bottom: 4px;
              margin-top: 0px;
              width: 40px;
            "
            class="error--text"
            @click="removeAdditionalSigner(idx)"
          >
            Poista
          </p>

          <v-text-field
            v-model="signer.name"
            label="Nimi"
            outlined
            dense
            hide-details
            :rules="validations.required"
          />
          <v-text-field
            class="mt-1"
            v-model="signer.email"
            label="Email"
            outlined
            dense
            :rules="validations.email.concat(duplicateEmail)"
          />
        </v-col>
      </v-row>

      <v-btn class="mt-1 mb-1" color="primary" small @click="addAdditionalSigner"
        >Lisää allekirjoittaja</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import validations from "@/validations";

export default {
  data(vm) {
    return {
      duplicateEmail: [
        (v) => !vm.isDuplicateEmail(v) || "Vastaanottajan sähköposti on jo käytössä",
      ],
      validations,
    };
  },

  computed: {
    ...mapState("signature", ["additionalSigners", "allSigners"]),
  },

  methods: {
    ...mapMutations("signature", ["addAdditionalSigner", "removeAdditionalSigner"]),

    isDuplicateEmail(email) {
      return this.allSigners.some((item) => item.email === email);
    },

    validate() {
      return this.$refs.rentalContractForm.validate();
    },
  },
};
</script>

<style></style>
