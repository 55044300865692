<template>
  <v-dialog v-model="dialog" width="550" persistent>
    <v-card>
      <v-card-title
        >Käsin kirjoitettu allekirjoitus sop.num. {{ signatureItem.contractNumber }}</v-card-title
      >

      <div v-if="!viewCanvas">
        <v-card-text class="mt-2">
          <!-- Signers -->
          <div class="mb-2">
            <h3 class="mb-1">Allekirjoittajat</h3>
            <div v-for="(signer, idx) in selectedSigners" :key="idx + 'a'">
              <strong>{{ signer.name }}</strong>
              <span v-if="signer.email && contractType != 'otherContract'">
                ({{ signer.email }})</span
              ><span v-if="!signer.email" class="error--text"> (Ei emailia)</span>

              <p
                :class="
                  signer.status == 'notSigned'
                    ? 'error--text'
                    : signer.status == 'pending'
                    ? 'warning--text'
                    : 'success--text'
                "
              >
                {{ formatSignatureStatus(signer.status) }}
              </p>

              <v-btn class="mb-1 mt-1 mr-1" small color="info" @click="openSignaturePad(signer)">{{
                signer.status == "notSigned" ? "Allekirjoita" : "Katso allekirjoitus"
              }}</v-btn>

              <v-btn
                v-if="signer.status != 'completed' && contractType == 'otherContract'"
                class="mb-1 mt-1"
                small
                outlined
                color="error"
                @click="removeSigner(signer)"
                >Poista allekirjoittaja</v-btn
              >

              <v-divider class="mb-2 mt-1"></v-divider>
            </div>

            <v-btn
              v-if="signatureItem.signaturePad.status == 'completed'"
              class="mb-1 mt-1"
              outlined
              color="error"
              @click="deleteAllSignatures()"
              >Poista allekirjoitusprosessi</v-btn
            >
          </div>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="signatureItem.signaturePad.status != 'completed'"
            :disabled="checkSignatures()"
            color="success"
            outlined
            @click="save()"
          >
            Allekirjoita dokumentti
          </v-btn>
        </v-card-actions>
      </div>

      <!-- Show signature canvas -->
      <signature-canvas v-if="viewCanvas" v-model="viewCanvas"></signature-canvas>
    </v-card>
  </v-dialog>
</template>

<script>
import mixins from "../../mixins/mixins";
import SignatureCanvas from "./SignatureCanvas.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    document: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  components: {
    SignatureCanvas,
  },

  data() {
    return {
      isReady: false,
      signers: [],
      profile: {},
      required: [(v) => !!v || "Pakollinen kenttä"],
      noCommas: [(v) => !v.includes(",") || "Poista pilkut sähköposteista"],
    };
  },

  computed: {
    ...mapState("signature", ["signatureItem", "contractType", "selectedSigners"]),

    viewCanvas: {
      get() {
        return this.$store.state.signature.showSignatureCanvas;
      },
      set(value) {
        this.showSignatureCanvas(value);
      },
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapActions("signature", ["signContract", "deleteSignatureProcess"]),
    ...mapMutations("contract", ["replaceContract"]),
    ...mapMutations("othercontract", ["replaceOtherContract"]),
    ...mapMutations("signature", [
      "showSignatureCanvas",
      "setCurrentSigner",
      "setSelectedSigners",
      "getSelectedSigners",
      "removeSigner",
    ]),

    startProcess() {
      this.getSelectedSigners();
    },

    save() {
      let success = true;
      this.selectedSigners.forEach((signer) => {
        if (!signer.signature) {
          success = false;
        }
      });

      if (success) {
        this.signContract();
      } else {
        this.showPopup("Kaikki allekirjoitukset ovat pakollisia", "error");
      }
    },

    deleteAllSignatures() {
      if (confirm("Haluatko varmasti poistaa kaikki allekirjoitukset?")) {
        this.deleteSignatureProcess();
      }
    },

    openSignaturePad(signer) {
      this.setCurrentSigner(signer);
      this.viewCanvas = true;
    },

    checkSignatures() {
      if (this.selectedSigners.length == 0) {
        return true;
      } else {
        let success = true;
        this.selectedSigners.forEach((signer) => {
          if (!signer.signature) {
            success = false;
          }
        });

        return !success;
      }
    },

    formatSignatureStatus(status) {
      let formatted;
      switch (status) {
        case "notSigned":
          formatted = "Ei allekirjoitettu";
          break;
        case "pending":
          formatted = "Allekirjoitettu, odottaa dokumentin allekirjoittamista";
          break;
        case "completed":
          formatted = "Allekirjoitettu";
          break;
      }

      return formatted;
    },
  },
};
</script>

<style scoped>
.card-oldlist {
  height: 500px;
  overflow-y: scroll;
}
</style>
