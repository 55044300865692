<template>
  <div align="center" v-resize="resizeCanvas">
    <!-- Canvas -->
    <div class="canvas-wrapper">
      <canvas class="canvas"></canvas>
    </div>

    <p class="mt-1">{{ currentSigner.name }}</p>

    <v-card-actions class="justify-center">
      <v-btn
        v-if="currentSigner.status != 'completed'"
        color="error"
        small
        outlined
        @click="signaturePad.clear()"
      >
        Tyhjennä
      </v-btn>
    </v-card-actions>

    <v-card-actions>
      <v-btn color="error" medium outlined @click="viewCanvas = false"> Takaisin </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="currentSigner.status != 'completed'"
        color="success"
        medium
        outlined
        :disabled="signaturePad ? signaturePad.isEmpty() : false"
        @click="save()"
      >
        Tallenna allekirjoitus
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import SignaturePad from "signature_pad";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      canvas: null,
      signaturePad: null,
      currentImage: null,
    };
  },

  mounted() {
    this.canvas = document.querySelector(".canvas");
    this.signaturePad = new SignaturePad(this.canvas);
    this.resizeCanvas();
    this.getCurrentImage();
    if (this.currentSigner.status == "completed") {
      this.signaturePad.off();
    }
  },

  computed: {
    ...mapState("signature", ["currentSigner"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    viewCanvas: {
      get() {
        return this.$store.state.signature.showSignatureCanvas;
      },
      set(value) {
        this.showSignatureCanvas(value);
      },
    },
  },

  methods: {
    ...mapMutations("signature", ["showSignatureCanvas", "saveSignature"]),

    getCurrentImage() {
      if (this.currentSigner.signature) {
        this.currentImage = this.signaturePad.fromDataURL(this.currentSigner.signature);
      }
    },

    save() {
      if (this.signaturePad.isEmpty()) {
        return this.showPopup("Et ole lisännyt allekirjoitusta.", "error");
      }
      this.saveSignature(this.signaturePad.toDataURL());
      this.viewCanvas = false;
      this.showPopup(`${this.currentSigner.name} on lisännyt allekirjoituksen`, "success");
    },

    resizeCanvas() {
      if (this.canvas) {
        if (this.currentImage) this.currentImage = this.signaturePad.toDataURL();

        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        this.canvas.width = this.canvas.offsetWidth * ratio;
        this.canvas.height = this.canvas.offsetHeight * ratio;
        this.canvas.getContext("2d").scale(ratio, ratio);
        this.signaturePad.clear();

        if (this.currentImage) this.signaturePad.fromDataURL(this.currentImage);
      }
    },
  },
};
</script>

<style scoped>
.canvas-wrapper {
  max-width: 500px;
  height: 200px;
  margin: 0 5px 0 5px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.canvas {
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;
}
</style>
