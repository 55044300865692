<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title>Valitse allekirjoitustapa</v-card-title>

      <v-card-text>
        <v-row dense>
          <!-- VISMA SIGN -->
          <v-col cols="12" sm="6">
            <v-card
              class="mx-auto fill-height"
              :class="{ isSelectedMethod: vismaSignActivated }"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Visma Sign</div>

                  <v-list-item-title class="text-h5 mb-1"
                    >Sähköinen allekirjoitus</v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Visma Signin sähköinen allekirjoitus vahvalla tunnistautumisella. 2,5
                    €/allekirjoitus 10 kpl asti. Sen jälkeen 2€/allekirjoitus.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn
                  :disabled="signaturePadInActivated || signheroActivated || !vismaSignInUse"
                  color="primary"
                  outlined
                  @click="
                    showVismaSignSignature = true;
                    $refs.vismaSignSignature.startProcess();
                  "
                >
                  Valitse
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- SIGNHERO -->
          <v-col cols="12" sm="6">
            <v-card
              class="mx-auto fill-height"
              :class="{ isSelectedMethod: signheroActivated }"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Signhero</div>

                  <v-list-item-title class="text-h5 mb-1"
                    >Sähköinen allekirjoitus</v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Signheron sähköinen allekirjoitus ilman vahvaa
                    tunnistautumista</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn
                  :disabled="signaturePadInActivated || vismaSignActivated || !signheroInUse"
                  color="primary"
                  outlined
                  @click="
                    showSignHeroSignature = true;
                    $refs.signheroSignature.startProcess();
                  "
                >
                  Valitse
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <!-- SIGNATURE PAD -->
          <v-col cols="12" sm="6">
            <v-card
              class="mx-auto fill-height"
              outlined
              :class="{ isSelectedMethod: signaturePadInActivated }"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Signature Pad</div>
                  <v-list-item-title class="text-h5 mb-1"
                    >Käsin kirjoitettu allekirjoitus</v-list-item-title
                  >
                  <v-list-item-subtitle
                    >Kirjoita allekirjoitus suoraan ruudulle sormella tai hiiren osoittimella.
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn
                  :disabled="signheroActivated || vismaSignActivated"
                  color="primary"
                  outlined
                  @click="
                    showSignaturePad = true;
                    $refs.signaturePad.startProcess();
                  "
                >
                  Valitse
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn outlined color="error" @click="dialog = false">Poistu</v-btn>
      </v-card-actions>
    </v-card>

    <!-- signhero dialog -->
    <signhero-signature
      ref="signheroSignature"
      v-model="showSignHeroSignature"
      @sendsignatureinvitation="sendSignatureInvitationSignHero"
      @createsignature="createSignature(signatureItem)"
    ></signhero-signature>

    <!-- vismasign dialog -->
    <visma-sign-signature
      ref="vismaSignSignature"
      v-model="showVismaSignSignature"
      @sendsignatureinvitation="sendSignatureInvitationVismaSign"
      @createsignature="createSignature(signatureItem)"
    ></visma-sign-signature>

    <!-- Signature pad -->
    <signature-pad v-model="showSignaturePad" ref="signaturePad"></signature-pad>
  </v-dialog>
</template>

<script>
import { apiAgent } from "../../services/apiAgent";
import axiosMethods from "../../mixins/axios";
import SignheroSignature from "./SignheroSignature.vue";
import VismaSignSignature from "./VismaSignSignature.vue";
import SignaturePad from "./SignaturePad.vue";
import mixins from "../../mixins/mixins";
import { mapState, mapMutations } from "vuex";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
  },

  components: {
    SignheroSignature,
    VismaSignSignature,
    SignaturePad,
  },

  data() {
    return {
      showSignHeroSignature: false,
      showVismaSignSignature: false,
      showSignaturePad: false,
    };
  },

  computed: {
    ...mapState("signature", ["signatureItem", "contractType"]),
    ...mapState("account", ["currentUser"]),

    signheroInUse() {
      return this.currentUser?.currentAccount?.signHeroToken?.token ? true : false;
    },

    signheroActivated() {
      return this.signatureItem?.signature?.processId ? true : false;
    },

    vismaSignInUse() {
      return this.currentUser?.currentAccount?.vismaSign?.organizationId ? true : false;
    },

    vismaSignActivated() {
      return this.signatureItem?.vismaSign?.documentUuId ? true : false;
    },

    signaturePadInActivated() {
      return this.signatureItem?.signaturePad?.status == "completed" ? true : false;
    },

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    ...mapMutations("signature", ["setSignatureItem", "setLoading"]),
    ...mapMutations("othercontract", ["replaceOtherContract"]),
    ...mapMutations("contract", ["replaceContract"]),

    async sendSignatureInvitationSignHero(signers) {
      if (
        confirm(
          `Haluatko lähettää vuokralaisen ${this.signatureItem.tenant.name} sopimuksen sähköiseen allekirjoitukseen Signhero-palveluun?`
        )
      ) {
        this.setLoading(true);

        try {
          let res = await apiAgent.post(
            "/api/v1/signature/signhero/uploadDocument/" + this.signatureItem._id,
            {
              signers,
              contractType: this.contractType,
            }
          );

          if (this.contractType == "rentalContract") {
            this.replaceContract({ ...res.data.contr });
          } else if (this.contractType == "otherContract") {
            this.replaceOtherContract({ ...res.data.contr });
          }

          this.setSignatureItem(res.data.contr);

          this.setLoading(false);
          this.showSignHeroSignature = false;
          this.showPopup("Sopimus lähetetty allekirjoitettavaksi", "success");
        } catch (err) {
          this.setLoading(false);
          const error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },

    async sendSignatureInvitationVismaSign(data) {
      if (
        confirm(
          `Haluatko lähettää vuokralaisen ${this.signatureItem.tenant.name} sopimuksen sähköiseen allekirjoitukseen Visma Sign-palveluun?`
        )
      ) {
        this.setLoading(true);

        try {
          let res = await apiAgent.post(
            "/api/v1/signature/vismasign/document/contract/" + this.signatureItem._id,
            {
              signers: data.signers,
              validUntilDate: data.validUntilDate,
              contractType: this.contractType,
            }
          );

          if (this.contractType == "rentalContract") {
            this.replaceContract({ ...res.data.contr });
          } else if (this.contractType == "otherContract") {
            this.replaceOtherContract({ ...res.data.contr });
          }

          this.setSignatureItem(res.data.contr);

          this.setLoading(false);
          this.showVismaSignSignature = false;
          this.showPopup("Sopimus lähetetty allekirjoitettavaksi", "success");
        } catch (err) {
          this.setLoading(false);
          const error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },

    async createSignature(item) {
      try {
        let res = await apiAgent.post("/api/v1/signature/signhero/createSignature/" + item._id, {
          contractType: this.contractType,
        });
        window.open(res.data.url, "_blank");
      } catch (err) {
        const error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style scoped>
.isSelectedMethod {
  border: 1px solid var(--v-success-base);
}

.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal !important;
  -webkit-line-clamp: unset !important;
}

.text-overline {
  font-size: 12px !important;
}
</style>
