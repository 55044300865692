export default {
  // Get rental contract signers

  getRentalContractSigners(signatureItem, currentUser, userIsAccountOwner) {
    let allSigners = [];
    let noEmailSigners = [];

    if (signatureItem.others.signForOthers) {
      const name = userIsAccountOwner
        ? currentUser.name
        : "Vuokranantajan puolesta " + currentUser.name;
      const email = userIsAccountOwner
        ? "Allekirjoitetaan Vuokranet-järjestelmästä"
        : currentUser.email;

      allSigners.push({
        name,
        email,
      });
    } else {
      // Landlords
      this.formatLandlords(signatureItem.landlords, "rentalContract").forEach((el) => {
        if (el.email) {
          if (this.userIsAccountOwner && el.email == currentUser.currentAccount.email) {
            allSigners.push({
              name: el.name,
              email: "Allekirjoitetaan Vuokranet-järjestelmästä",
            });
          } else {
            allSigners.push({
              name: el.name,
              email: el.email,
            });
          }
        } else {
          noEmailSigners.push(el.name);
        }
      });
    }

    // Tenant
    if (signatureItem.tenant.email) {
      allSigners.push({
        name: signatureItem.tenant.name,
        email: signatureItem.tenant.email,
      });
    } else {
      noEmailSigners.push(signatureItem.tenant.name);
    }

    // Other tenants
    signatureItem.otherTenants.forEach((el) => {
      if (el.email) {
        allSigners.push({ name: el.name, email: el.email });
      } else {
        noEmailSigners.push(el.name);
      }
    });

    return { allSigners, noEmailSigners };
  },

  // Get other contract signers

  getOtherContractSigners(signatureItem) {
    let allSigners = [];
    let noEmailSigners = [];

    this.formatLandlords(signatureItem.landlords, "otherContract").forEach((el) => {
      if (el.email) {
        allSigners.push({ name: el.name, email: el.email });
      } else {
        noEmailSigners.push(el.name);
      }
    });

    // Tenant
    if (signatureItem.tenant.email) {
      allSigners.push({
        name: signatureItem.tenant.name,
        email: signatureItem.tenant.email,
      });
    } else {
      noEmailSigners.push(signatureItem.tenant.name);
    }

    // Other tenants
    signatureItem.otherTenants.forEach((el) => {
      if (el.email) {
        allSigners.push({
          name: el.name,
          email: el.email,
        });
      } else {
        noEmailSigners.push(el.name);
      }
    });

    return { allSigners, noEmailSigners };
  },

  // helpers

  formatLandlords(landlords, contractType) {
    if (contractType == "rentalContract") {
      return landlords;
    } else if (contractType == "otherContract") {
      return landlords.map((el) => el.landlordId);
    }
  },
};
