<template>
  <!-- Signers -->
  <div class="mb-2">
    <h3 class="mb-1">Allekirjoittajat</h3>

    <div v-for="(person, idx) in allSigners" :key="idx + 'a'">
      <p
        v-if="contractType == 'otherContract'"
        style="margin-bottom: 0px; margin-top: 4px; font-size: 11px; cursor: pointer"
        @click="removeFromAllSigners(idx)"
        class="error--text"
      >
        Poista
      </p>
      <span
        ><strong>{{ person.name }}</strong> ({{ person.email }})</span
      >
    </div>

    <!-- Additional signers component -->
    <additional-signers ref="additionalSigners"></additional-signers>

    <!-- Potential signers with no email -->
    <h3 v-if="noEmailSigners.length > 0" class="mb-1 mt-2">Nämä eivät voi allekirjoittaa</h3>
    <div v-for="(name, idx) in noEmailSigners" :key="idx + 'b'">
      <span
        ><strong class="error--text">{{ name }} (Ei emailia)</strong></span
      >
    </div>
  </div>
</template>

<script>
import AdditionalSigners from "./AdditionalSigners.vue";
import { mapMutations } from "vuex";

export default {
  props: ["allSigners", "noEmailSigners", "contractType"],

  components: {
    AdditionalSigners,
  },

  methods: {
    ...mapMutations("signature", ["removeFromAllSigners"]),

    validateAdditionalSigners() {
      return this.$refs.additionalSigners.validate();
    },
  },
};
</script>

<style></style>
